import React, { useState } from 'react';
import { useLibNSTranslation } from '../../utils/i18nUtils';
import {
  convertDateToUtc,
  createDateList,
  createSelectInputLabelOperator,
  getDateRangeValues,
  getInitialLabel,
} from '../utils/DateRangeFilterUtils';
import { Button } from '../../Button';
import { Caret } from '../../Icons';
import { DateRangePicker } from '../../DateRangePicker';
import SelectInput from './SelectInput';
import { DateRangeFilterProps } from '../../types/EnhancedSearch';

export default function DateRangeFilter({
  field,
  filter,
  localDate,
  onFilterChange,
}: DateRangeFilterProps) {
  const { t } = useLibNSTranslation();

  const [selectLabel, setSelectLabel] = useState(
    getInitialLabel(filter, localDate)
  );
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);

  /* -----> Utils <----- */
  /* Filters falsy values, converts to local date if needed and fires onFilterChange handler */
  const fireFilterChange = (dates, operator = 'BETWEEN') => {
    const values = dates
      .filter(date => date)
      .map((date, index) => {
        if (localDate) return new Date(date).toISOString().split('T')[0];
        return convertDateToUtc(date, index === 1);
      });
    const updatedFilter = { ...filter, operator, values };

    onFilterChange(updatedFilter);
  };

  /* -----> Handlers <----- */
  const onCustomDatesClick = dates => {
    const { operator, inputLabel } = createSelectInputLabelOperator(dates);

    fireFilterChange(dates, operator);
    setSelectLabel(inputLabel);
  };

  return (
    <div className="filterSelect">
      <SelectInput
        className="dateRangeFilter"
        fieldName={field.name}
        label={field.label}
        width={250}
        selectedContent={[selectLabel]}
        content={
          <div>
            {isDatePickerOpen ? (
              <div className="filter-content">
                <section className="top-section">
                  <Button
                    theme="none"
                    onClick={() => setIsDatePickerOpen(false)}
                  >
                    <Caret />
                    <span>{t('enhancedSearch.date.selectRange')}</span>
                  </Button>
                </section>
                <DateRangePicker
                  startDate={getDateRangeValues(filter, localDate)[0]}
                  startLabel={t('enhancedSearch.date.startDate')}
                  endDate={getDateRangeValues(filter, localDate)[1]}
                  endLabel={t('enhancedSearch.date.endDate')}
                  onChangeDate={onCustomDatesClick}
                />
              </div>
            ) : (
              <ul className="button-list">
                {createDateList().map(item => (
                  <li key={item.label}>
                    <Button
                      theme="none"
                      onClick={() => {
                        setSelectLabel(item.label);
                        fireFilterChange(item.dates);
                      }}
                    >
                      {item.label}
                    </Button>
                  </li>
                ))}
                <li>
                  <Button
                    theme="none"
                    onClick={() => setIsDatePickerOpen(true)}
                  >
                    {t('enhancedSearch.date.selectRange')}
                  </Button>
                </li>
              </ul>
            )}
          </div>
        }
      />
    </div>
  );
}
