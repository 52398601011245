import React, { useState } from 'react';
import { useLibNSTranslation } from '../../utils/i18nUtils';
import { Radio } from '../../Radio';
import { Text } from '../../Text';
import { Button } from '../../Button';
import SelectInput from './SelectInput';
import {
  applyOperator,
  applyValue,
  findDefaultStringFilterOperator,
} from '../utils/StringFilterUtils';
import { useDebounce } from '../../Hooks/useDebounce';
import { StringFilterProps } from '../../types/EnhancedSearch';
import { CUSTOM_OPERATORS, UNARY_OPERATORS } from '../constants';
import { Textarea } from '../../Textarea';

export default function StringFilter({
  field,
  filter,
  onFilterChange,
  tooltipText,
}: StringFilterProps) {
  const { t } = useLibNSTranslation();
  const debouncedOnFilterChange = useDebounce(onFilterChange, 250);
  const defaultOperator = findDefaultStringFilterOperator(field);

  const [isAdvancedSearchOpened, setIsAdvancedSearchOpened] = useState(false);
  const [operator, setOperator] = useState(
    applyOperator(filter) || defaultOperator
  );
  const [textValue, setTextValue] = useState(applyValue(filter));

  /* -----> Utils <----- */
  const usingCSL = (usedOperator: string = operator) =>
    usedOperator === CUSTOM_OPERATORS.COMMA_SEPARATED_LIST;

  const fireFilterChange = (textValue: string, eventOperator: string): void => {
    let values: string[] = [];
    const currentOperator = usingCSL(eventOperator) ? 'EQUAL' : eventOperator;

    if (usingCSL(eventOperator)) {
      values = textValue
        ?.split(',')
        ?.slice(0, 1000)
        ?.map(str => str.trim())
        ?.filter(str => str.length > 0)
    } else {
      values = !UNARY_OPERATORS.includes(currentOperator) ? [textValue] : [];
    }

    debouncedOnFilterChange({ ...filter, operator: currentOperator, values });
  };

  const toggleAdvancedSearch = () => {
    setIsAdvancedSearchOpened(!isAdvancedSearchOpened);
  };

  /* -----> Handlers <----- */
  const onTextChange = event => {
    const textValue = event.target.value;

    setTextValue(textValue);
    fireFilterChange(textValue, operator);
  };

  const onOperatorChange = event => {
    const eventOperator = event.target.value;

    setOperator(eventOperator);
    fireFilterChange(textValue, eventOperator);
  };

  return (
    <div className="filterSelect">
      <SelectInput
        className="stringFilter"
        fieldName={field.name}
        label={field.label}
        tooltipText={tooltipText}
        width={usingCSL() ? 300 : null}
        selectedContent={
          textValue || operator !== defaultOperator
            ? [
                `${t(`enhancedSearch.operators.${operator}`).toLowerCase()} ${
                  textValue && !UNARY_OPERATORS.includes(operator)
                    ? textValue
                    : ''
                }`,
              ]
            : []
        }
        content={
          <div className="filter-content">
            {!usingCSL() ? (
              <Text
                data-testid="string-filter-input"
                disabled={UNARY_OPERATORS.includes(operator)}
                onChange={onTextChange}
                placeholder={`${t('enhancedSearch.filters.placeholderLabel', {
                  field: field.label,
                })} `}
                value={textValue}
              />
            ) : (
              <Textarea
                data-testid="string-filter-input"
                disabled={UNARY_OPERATORS.includes(operator)}
                onChange={onTextChange}
                placeholder={`${t('enhancedSearch.filters.placeholderLabel', {
                  field: field.label,
                })} `}
                value={textValue}
              />
            )}
            <div className="advancedSearchToggle">
              <Button theme="text" onClick={toggleAdvancedSearch}>
                {t('enhancedSearch.filters.advancedSearch')}
              </Button>
            </div>
            {isAdvancedSearchOpened && (
              <div className="advancedSearchOperators">
                {field.supportedOperators?.map(supportedOperator => (
                  <Radio
                    checked={supportedOperator === operator}
                    data-testid={supportedOperator}
                    key={supportedOperator}
                    onChange={onOperatorChange}
                    value={supportedOperator}
                  >
                    {t(`enhancedSearch.operators.${supportedOperator}`)}
                  </Radio>
                ))}
              </div>
            )}
          </div>
        }
      />
    </div>
  );
}
