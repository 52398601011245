import React, { useContext, useEffect, useRef, useState } from 'react';
import { cx } from '../utils';
import { Clickable } from '../Button';
import { Clipboard } from '../Icons';
import { BellIcon } from '../Icons';
import { NotificationItem } from '../types';
import { UserSettingsContext } from '../Contexts';
import { useTranslation } from 'react-i18next';

/**
 * It is a menu item part of the top navigation
 * It requires `UserSettingsContext.notificationData` in order to show.
 */
export default function NotificationsMenu() {
  const { t } = useTranslation();
  /* Set these to null, when the user shouldn't see the menu */
  const items: NotificationItem[] | null =
    useContext(UserSettingsContext)?.notificationItems;

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);

  const toggleMenuHandler = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const onBlurHandler = () => {
    setIsMenuOpen(false);
  };

  const onClickHandler = (e: MouseEvent) => {
    if (
      !menuRef.current ||
      !menuRef.current?.contains(e.target as HTMLDivElement)
    ) {
      setIsMenuOpen(false);
    }
  };

  const onKeyDownHandler = (e: KeyboardEvent) => {
    if (e.key === 'Escape') {
      setIsMenuOpen(false);
    }
  };

  useEffect(() => {
    window.addEventListener('click', onClickHandler);
    window.addEventListener('blur', onBlurHandler);
    window.addEventListener('keydown', onKeyDownHandler);

    return () => {
      window.removeEventListener('click', onClickHandler);
      window.removeEventListener('blur', onBlurHandler);
      window.removeEventListener('keydown', onKeyDownHandler);
    };
  });

  return Array.isArray(items) ? (
    <div
      className="setting-container"
      style={{ position: 'relative' }}
      ref={menuRef}
    >
      <Clickable
        data-testid="notifications-menu-button"
        className={cx('notifications-menu-button', {
          'new-notifications': items?.length > 0,
        })}
        onClick={toggleMenuHandler}
      >
        <div
          className="notifications-menu-button-icon"
          onClick={toggleMenuHandler}
        >
          <BellIcon />
        </div>
      </Clickable>
      <div
        className={cx('notifications-dropdown-container', { open: isMenuOpen })}
      >
        <ul
          aria-expanded={isMenuOpen}
          className="notifications-dropdown"
          data-testid="notifications-dropdown"
        >
          {items.length > 0 ? (
            items.map(item => {
              return (
                <li className="menu-notification" key={item.name}>
                  <div className="icon">
                    <Clipboard />
                  </div>
                  <div className="content">
                    <p className="notification-title">{item.name}</p>
                    <a href={item.path} className="notification-location">
                      {item.label}
                    </a>
                  </div>
                </li>
              );
            })
          ) : (
            <li className="menu-notification no-notifications">
              <div className="content">{t('notification.noNotifications')}</div>
            </li>
          )}
        </ul>
      </div>
    </div>
  ) : null;
}
